import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as Search } from '../../assets/images/search-line.svg';
import PrizeBox from '../../components/PrizeBox/PrizeBox';
import Carousel from '../../components/Ui/Carousel/Carousel';
import Heading from '../../components/Ui/Heading/Heading';
import { respondTo } from '../../theme/mixin';
import Pagination from '../../components/Pagination';
import useSearchParam from '../../hooks/useSearchParam';
import { getProducts } from '../../store/actions/products';
import { Loader } from '../../components/Ui';

function Prizes() {
  let params = useSearchParam();
  const { data, last_page, per_page } = useSelector(state => state.products?.products || {});
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(params.get('page') || 1);
  // const per_page = 3;

  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentPage(params.get('page'));
  }, [params.get('page')]);

  useEffect(() => {
    setLoading(true);
    setFiltered(null);
    dispatch(getProducts(currentPage)).then(() => {
      setLoading(false);
    });
  }, [currentPage, dispatch]);

  const searchHandler = () => {
    params.set('page', '1');
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    setCurrentPage(1);
    dispatch(getProducts(1, search)).then(() => {
      setLoading(false);
      setSearch(null);
    });
  };

  return (
    <Container>
      <div className='search'>
        <div className='search-field'>
          <input
            value={search}
            onChange={e => setSearch(e.target.value)}
            type='text'
            placeholder='Cerca nel catalogo'
          />
          <Search onClick={searchHandler} className='search-icon' />
        </div>
      </div>
      <Heading className='mt-32'>Tutti i premi</Heading>
      {loading ? (
        <Loader show={loading} />
      ) : (
        <div className='prizes'>
          {data?.length > 0 && data?.map(item => <PrizeBox {...item} key={item.id} />)}
        </div>
      )}
      <Pagination currentPage={currentPage} totalePages={last_page} />
    </Container>
  );
}
const Container = styled.div`
  .prizes {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    ${respondTo.sm`   
       grid-template-columns: repeat(4, 1fr);
    `}
  }
  ${respondTo.sm`   
        padding: 88px 16px 0 16px;
    `}
  .slick-slide
    > div {
    display: flex;
    margin: 0 -16px;
  }
  .search {
    background-color: ${props => props.theme.primary};
    padding: 25px 16px 10px;
    box-shadow: 0px 2px 16px #31181829;
    border-radius: 0px 0px 14px 14px;
    margin-top: -28px;
    ${respondTo.sm`   
       margin-top: 0;
    `}
    &-field {
      input {
        border: none;
        outline: none;
        flex-grow: 1;
      }
      svg {
        cursor: pointer;
      }
      width: 100%;
      padding: 7px;
      display: inline-flex;
      align-items: center;
      height: 32px;
      background: #ffffff;
      border-radius: 16px;
      overflow: hidden;
      ${respondTo.sm`   
       width: 300px;
    `}
    }
  }
`;

export default Prizes;
