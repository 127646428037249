import React from 'react';
import styled from 'styled-components';
import MainContainer from '../../components/MainContainer/MainContainer';
function Layout({ children, banner }) {
  return (
    <Wrapper>
      <img src={banner} alt='banner' />
      <Container className='mt--50'>{children}</Container>
    </Wrapper>
  );
}
const Container = styled(MainContainer)``;
const Wrapper = styled.div`
  img {
    width: 100%;
  }
`;
export default Layout;
