import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import Routes from './Routes/Routes';
import Favicon from './components/Favicon';
import GDPR from './components/GDPR';
import GenericScripts from './components/GenericScripts';
import GlobalFont from './components/GlobalFont';
import GlobalStyle from './components/GlobalStyle';
import GoogleAnalytics from './components/GoogleAnalytics';
import { Loader, Button, Modal, Text } from './components/Ui';
import * as actionCreators from './store/actions';
import NotFound from './views/NotFound';

import { ThemeProvider } from 'styled-components';
import { configTheme, theme } from './theme/theme';

import { getNews } from './store/actions/news';
import { getNotifications } from './store/actions/notifications';
import { getOrders } from './store/actions/orders';
import { getPartnerList } from './store/actions/partners';
import { getAbbProducts, getHomeProducts, getProducts } from './store/actions/products';
import { getFirstRoute, getSlug } from './utils/utils';
import customaxios from './config/axios-refresh-token';


function App(props) {
  const r = getFirstRoute();

  const {
    getUserInfo,
    isLogged,
    appConfig,
    appConfig: { lang, analytics, favicon, settings, featured_message },
    appError,
    loadingOauthSendToken,
    errorOauthSendoToken,
    bootApp,
    loading,
    getGames,
  } = props;

  const [googleAnalytics, setGoogleAnalytics] = useState(null);
  const [fav, setFav] = useState(null);
  const [fontSource, setFontSource] = useState(null);
  const [showGdpr, setShowGdpr] = useState(false);
  const [showCompetitionModal, setShowCompetitionModal] = useState(false);
  const [showHolidayModal, setShowHolidayModal] = useState(false);
  const token = useSelector(state => state.user.user?.token);

  const dispatch = useDispatch();
  const [t, i18] = useTranslation();
  const multipromo = settings?.multipromo;
  let slug = 'get';
  if (multipromo) {
    slug = getSlug();
  }
  configTheme(appConfig);

  useEffect(() => {
    if (isLogged) {
      dispatch(getPartnerList());
      dispatch(getOrders());
      dispatch(getProducts());
      dispatch(getHomeProducts());
      dispatch(getAbbProducts());
      dispatch(getNotifications());
      dispatch(getNews());
    }
  }, [isLogged]);

  useEffect(() => {
    bootApp();
  }, []);

  useEffect(() => {
    setGoogleAnalytics(analytics);
  }, [analytics]);

  useEffect(() => {
    setFav(favicon);
  }, [favicon]);

  useEffect(() => {
    i18.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    if (isLogged) {
      getGames(slug, isLogged);
      getUserInfo();
    }
  }, [isLogged]);

  useEffect(() => {
    if (appConfig) {
      if (appConfig.design) {
        if (appConfig.design.template_props.font_source) {
          setFontSource(appConfig.design.template_props.font_source);
        }
      }
      if (appConfig.i18l) {
        setShowGdpr(true);
      }
    }
  }, [appConfig]);

  let err = null;
  let errType = null;

  if (appError || errorOauthSendoToken) {
    if (appError) {
      err = appError;
      errType = 'app';
    }
    if (errorOauthSendoToken) {
      err = errorOauthSendoToken;
      errType = 'auth';
    }
  }

  const userId = useSelector(state => state.user.user?.userinfo?.id);

useEffect(() => {
  if (!userId) {
    checkAndShowFeaturedMessage();
    return;
  }

  const currentDate = new Date();
  const competitionStartDate = new Date('2024-12-20T00:00:00');
  const competitionEndDate = new Date('2026-12-31T23:59:59');
  const isWithinCompetitionRange = currentDate >= competitionStartDate && currentDate <= competitionEndDate;

  const modalKey = `competitionModalShown_${userId}`;
  const competitionModalAlreadyShown = localStorage.getItem(modalKey) === 'true';

  if (isWithinCompetitionRange && !competitionModalAlreadyShown) {
    setShowCompetitionModal(true);
  } else {
    checkAndShowFeaturedMessage();
  }
}, [userId, appConfig]);


  const checkAndShowFeaturedMessage = () => {
    if (!featured_message || !featured_message.enabled) {
      return;
    }

    const currentDate = new Date();
    const holidayStartDate = new Date(`${featured_message.start_date}T00:00:00`); 
    const holidayEndDate = new Date(`${featured_message.end_date}T23:59:59`);

    if (currentDate >= holidayStartDate && currentDate <= holidayEndDate) {
      // Controlliamo lo SHA256Sum
      const savedShaSum = localStorage.getItem('holidayModalShaSum');
      if (savedShaSum !== featured_message.SHA256Sum) {
        // Lo SHA256Sum è differente o non presente, mostriamo la modale
        setShowHolidayModal(true);
      }
    }
  };

  const handleCloseCompetitionModal = async () => {
    try {
      // Chiamata POST al backend
      const response = await customaxios.post('/auth/me/consent', {
        consent_name: 'proroga_concorso_2024'
      });

      // Controllo della risposta
      if (response.status !== 200) {
        console.error('Errore nella chiamata v1/auth/me/consent');
      }
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
    }

    const modalKey = `competitionModalShown_${userId}`;
    localStorage.setItem(modalKey, 'true');
    setShowCompetitionModal(false);
    checkAndShowFeaturedMessage();
  };

  const handleCloseHolidayModal = () => {
    // Alla chiusura della modale salviamo lo SHA256Sum attuale
    if (featured_message && featured_message.SHA256Sum) {
      localStorage.setItem('holidayModalShaSum', featured_message.SHA256Sum);
    }
    setShowHolidayModal(false);
  };

  return (
    <React.Fragment>
      <GlobalFont font_source={fontSource} />
      <Favicon favicon={fav} />
      <GenericScripts />
      <Loader initial show={loading || (r === 'oauth' ? loadingOauthSendToken : null)} />

      {loading ? null : err ? (
        <NotFound type={errType} error={err} />
      ) : (
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader show={true} full />}>
            <React.Fragment>
              <GlobalStyle />
              <Routes />
            </React.Fragment>
          </Suspense>
      {/* Modale Proroga Concorso */}
      <Modal show={showCompetitionModal} close={handleCloseCompetitionModal}>
        <Text>
        Gentile utente, ti informiamo che il programma è stato prorogato e i punti potranno essere utilizzati fino al 31 marzo 2026. 
        Per ulteriori informazioni consulta il{' '}
                <a
                  href={`https://abbpremiplus.it/app/MODIFICA_Regolamento_OP_ABB_Premi_Plus_2024_2025.pdf`}
                  target='blank'
                  className='link text-underline'
                >
                  regolamento aggiornato
                </a> 
        </Text>
      </Modal>
      {/* Modale festività */}
      {featured_message && (
            <Modal show={showHolidayModal} close={handleCloseHolidayModal}>
              <Text size={30} bold margin='0 0 32px' dangerouslySetInnerHTML={{__html: featured_message.title}}></Text>
              <Text dangerouslySetInnerHTML={{__html: featured_message.content}}></Text>
              <Button
                style={{ marginTop: '20px' }}
                active
                fullwidth
                onClick={handleCloseHolidayModal}
              >
                OK
              </Button>
            </Modal>
          )}
        </ThemeProvider>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    appError: state.app.errorApp,
    appConfig: state.app?.config,
    loadingOauthSendToken: state.auth.loadingOauthSendToken,
    errorOauthSendoToken: state.auth.errorOauthSendoToken,
    loading: state.bootApp.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
    getGames: (slug, isLogged) => dispatch(actionCreators.getGames(slug, isLogged)),
    bootApp: () => dispatch(actionCreators.bootApp()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
