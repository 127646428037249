import styled from 'styled-components';
import arrow from '../../../../assets/images/arrowDown.svg';
const Select = styled.select.attrs(({}) => {
  return {};
})`
  &:invalid {
    color: #47545d59;
  }
  display: block;
  font-size: 12px;
  font-family: sans-serif;
  font-weight: 500;
  font-family: ${props => props.theme.fontFamily};
  color: #000;
  padding: 0 15px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  height: 32px;
  border-radius: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* color: ${props => props.theme.primary}; */
  background-color: ${props => props.theme.input.bg};
  /// background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10.607' height='6.482' viewBox='0 0 10.607 6.482'><path id='Tracciato_8611' data-name='Tracciato 8611' d='M10.939,12.347l4.125-4.125L16.243,9.4l-5.3,5.3-5.3-5.3L6.814,8.222Z' transform='translate(-5.636 -8.222)' fill='#a9a9a9'/></svg>");
  background-image: url(${arrow});
  background-position: right 10px top 12px, 0 0;
  background-repeat: no-repeat;
  background-size: 12px auto, 100%;
  border: none;
  border-radius: 16px;
  border: 2px solid
    ${props => {
      return (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg;
    }};
  &::placeholder {
    color: #47545d59;
  }
  /*   &:required:invalid {
    color: #47545d59;
  }
  option[value=''][disabled] {
    display: none;
  }
  option {
    color: black;
  } */
`;

export default Select;
