import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';

const LogoContainer = styled.div`
  /* max-width: 200px; */
  /* ${respondTo.sm`
       max-width: 160px;
    `}
  ${respondTo.sm58`
       max-width: 200px;
    `} */
  /*  max-width: ${props => (props.theme.logo_size ? props.theme.logo_size + 'px' : 'auto')}; */
  /*  margin-right: ${props => (props.center ? 'auto' : '60px')}; */
  /*   margin-left: auto; */
  img {
    display: block;
    max-width: 160px;
    ${respondTo.sm58`
       max-width: 215px;
    `}
  }
`;

const Logo = props => {
  const { nolink, logo } = props;

  let logoContent = null;

  if (nolink) {
    logoContent = <img src={logo} alt='' />;
  } else {
    logoContent = (
      <Link to='/'>
        <img src={logo} alt='' />
      </Link>
    );
  }

  return <LogoContainer {...props}>{logoContent}</LogoContainer>;
};

Logo.propTypes = {
  nolink: PropTypes.bool,
  logo: PropTypes.string,
};

export default Logo;
