import React, { useState } from 'react';
import InputField from '../InputField/InputField';

const InputTime = props => {
  const [isEditing, setIsEditing] = useState(false);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  return isEditing ? (
    <InputField
      {...props.elementConfig}
      {...props}
      onChange={props.changed}
      onBlur={toggleEditing}
    />
  ) : (
    <InputField
      {...props.elementConfig}
      {...props}
      type='text'
      value={props.value ? props.value : ''}
      onFocus={toggleEditing}
      readOnly
    />
  );
};

export default InputTime;
