import customAxios from '../../config/axios-refresh-token';
import * as actionTypes from './actionTypes';

const getProductsStart = () => {
  return {
    type: actionTypes.GET_PRODUCTS_START,
  };
};

const getProductsSuccess = products => {
  return {
    type: actionTypes.GET_PRODUCTS_SUCCESS,
    products,
  };
};

const getProductsError = error => {
  return {
    type: actionTypes.GET_PRODUCTS_ERROR,
    error,
  };
};

const getHomeProductsSuccess = products => {
  return {
    type: actionTypes.GET_HOME_PRODUCTS_SUCCESS,
    products,
  };
};

export const getHomeProducts = () => {
  let endpoint = `/catalog/products`;

  return dispatch => {
    dispatch(getProductsStart());
    return new Promise((resolve, reject) => {
      customAxios
        .get(endpoint, {
          params: {
            home: true,
          },
        })
        .then(response => {
          dispatch(getHomeProductsSuccess(response.data));
          resolve();
        })
        .catch(error => {
          dispatch(getProductsError(error));
          reject(error);
        });
    });
  };
};

export const getProducts = (page = 1, search) => {
  let endpoint = search
    ? `/catalog/products${`?page=1&limit=30&search=${search}`}`
    : `/catalog/products${page ? `?page=${page}` : ''}`;

  return dispatch => {
    dispatch(getProductsStart());
    return new Promise((resolve, reject) => {
      customAxios
        .get(endpoint)
        .then(response => {
          dispatch(getProductsSuccess(response.data));
          resolve();
        })
        .catch(error => {
          dispatch(getProductsError(error));
          reject(error);
        });
    });
  };
};
const getAbbProductsStart = () => {
  return {
    type: actionTypes.GET_ABB_PRODUCTS_START,
  };
};
const getAbbProductsSuccess = products => {
  return {
    type: actionTypes.GET_ABB_PRODUCTS_SUCCESS,
    products,
  };
};

const getAbbProductsError = error => {
  return {
    type: actionTypes.GET_ABB_PRODUCTS_ERROR,
    error,
  };
};
export const getAbbProducts = () => {
  let endpoint = `/abbproducts`;
  return dispatch => {
    dispatch(getAbbProductsStart());
    return new Promise((resolve, reject) => {
      customAxios
        .get(endpoint)
        .then(response => {
          dispatch(getAbbProductsSuccess(response.data));
          resolve();
        })
        .catch(error => {
          dispatch(getAbbProductsError(error));
          reject(error);
        });
    });
  };
};

const getProductStart = () => {
  return {
    type: actionTypes.GET_SINGLE_PRODUCT_START,
  };
};

const getProductSuccess = singleProduct => {
  return {
    type: actionTypes.GET_SINGLE_PRODUCT_SUCCESS,
    singleProduct,
  };
};

const getProductError = error => {
  return {
    type: actionTypes.GET_SINGLE_PRODUCT_ERROR,
    error,
  };
};

export const getProduct = productId => {
  let endpoint = `/catalog/products/${productId}`;
  return dispatch => {
    dispatch(getProductStart());
    return new Promise((resolve, reject) => {
      customAxios
        .get(endpoint)
        .then(response => {
          dispatch(getProductSuccess(response.data));
          resolve();
        })
        .catch(error => {
          dispatch(getProductError(error));
          reject(error);
        });
    });
  };
};
