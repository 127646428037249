import React from 'react';
import { Button, Col, Flex, Text } from '../Ui';
import logo from '../../assets/images/logo.png';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  img {
    height: auto;
  }
  .btn {
    margin-top: auto;
  }
`;

function AuthForm() {
  const history = useHistory();

  const registerHandle = () => {
    window.fbq('track', 'Contact');
    history.push('/auth/register');
  };

  return (
    <Container>
      <img src={logo} alt='logo' />
      <Text bold size={35} style={{ marginTop: 40 }} color='#000'>
        Ciao, sei un
        <br /> nuovo utente? <br />
        Registrati
      </Text>
      <Text className='mt-10 mb-20'>
        Se sei già registrato, invece, tocca il
        <br /> pulsante Accedi.
      </Text>
      <Flex
        direction='column'
        justify='center'
        align='center'
        style={{ marginTop: 'auto', gap: '20px' }}
      >
        <Col width={100} style={{ padding: '0' }}>
          <Button upper fullwidth active className='btn' onClick={() => registerHandle()}>
            REGISTRATI
          </Button>
        </Col>
        <Col width={100} style={{ padding: '0' }}>
          <Button blackColor upper fullwidth>
            <Link to='/auth/login'>ACCEDI</Link>
          </Button>
        </Col>
      </Flex>
    </Container>
  );
}

export default AuthForm;
