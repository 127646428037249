import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  products: [],
  homeProducts: [],
  singleProduct: {},
  abbProducts: [],
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.products,
      };
    case actionTypes.GET_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.GET_HOME_PRODUCTS_SUCCESS:
      return {
        ...state,
        home_loading: false,
        home_products: action.products,
      };
    case actionTypes.GET_ABB_PRODUCTS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_ABB_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        abbProducts: action.products,
      };
    case actionTypes.GET_ABB_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.GET_SINGLE_PRODUCT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        singleProduct: action.singleProduct,
      };
    case actionTypes.GET_SINGLE_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default productsReducer;
