import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import { Text, Box, Flex, Col } from '../Ui';
import PrizeBox from '../PrizeBox/PrizeBox';
import { useSelector } from 'react-redux';
import AdvantageBox from '../AdvantageBox/AdvantageBox';

const GioccaEVinci = ({ cms, prizes }) => {
  const content = useSelector(state =>
    state.cms.game[0].contents.find(e => e.name === 'giocca_vinci'),
  );
  const prize1 = useSelector(state =>
    state.cms.game[0].contents.find(e => e.name === 'prime1_text'),
  );
  const prize2 = useSelector(state =>
    state.cms.game[0].contents.find(e => e.name === 'prime2_text'),
  );
  const imgPrize1 = useSelector(
    state => state.cms.game[0].contents.find(e => e.name === 'imgPrime1')?.img,
  );
  const imgPrize2 = useSelector(
    state => state.cms.game[0].contents.find(e => e.name === 'ImgPrime2')?.img,
  );
  const partners = useSelector(state => state.partners.partnerList);
  const options = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <Box style={{ height: '100%', padding: '15px 10px' }}>
        <Text size={34} primary className='mb-20'>
          {content?.i18l.title}
        </Text>
        <Text as='div' className='mb-20'>
          {content?.i18l.content}
        </Text>

        {/* <Slider {...options}>
          {prizes?.map((prize, prizeIndex) => (
            <PrizeBox key={prizeIndex} prize={prize} />
          ))}
        </Slider> */}
        <Flex align='stretch'>
          {partners.map(item => (
            <Col
              width={partners.length == 1 ? 100 : 50}
              className='sm_mb-20'
              key={item.id}
              padding={5}
            >
              <AdvantageBox partner={item} />
            </Col>
          ))}
        </Flex>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  .slick-slide {
    padding: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }

  .slick-track {
    margin-left: 0;
  }
`;

export default GioccaEVinci;
