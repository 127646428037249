import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';

const MainContainer = styled.div`
  width: 100%;
  ${respondTo.sm`
  width: calc(100% - 32px);
  margin: auto
`}
`;

export default MainContainer;
