import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../Ui';
import { useTranslation } from 'react-i18next';

const ErrorInModal = ({ error, fromTranslation }) => {
  const [t] = useTranslation();

  let e = error;

  if (typeof error === 'object' && error !== null) {
    e = error.error;
  }

  return <Text as='p'>{fromTranslation ? (e ? t(`${e}`) : '') : e}</Text>;
};

ErrorInModal.defaultProps = {
  fromTranslation: false,
};

ErrorInModal.propTypes = {
  error: PropTypes.any,
  fromTranslation: PropTypes.bool,
};

export default ErrorInModal;
