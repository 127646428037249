import React from 'react';

import { Box, Flex, Text, Col, Button } from '../../components/Ui';

import Fade from '../../components/Fade/Fade';

import GioccaEVinci from '../../components/gioccaEVinci';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { Link } from 'react-router-dom';

const GioccaVinci = props => {
  const image = useSelector(
    state => state.cms.game[0].contents.find(e => e.name === 'giocca_vinci_image')?.img,
  );
  return (
    <Fade>
      <Flex align='stretch'>
        <Col width={30} padding={0}>
          <GioccaEVinci />
        </Col>
        <Col width={70} style={{ height: '100%' }} className='sm_mt-20'>
          <Box>
            <Text size={24} capit>
              compila con i dati richiesti
            </Text>
            <Flex>
              <Col width={50}></Col>
              <Col width={50}>
                <img src={image} />
              </Col>
            </Flex>
          </Box>
        </Col>
      </Flex>
      <CustomButton>
        <Link to='promo/game/1'>
          <Button white fullwidth>
            Giocca
          </Button>
        </Link>
      </CustomButton>
    </Fade>
  );
};
const CustomButton = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.primary};
  padding: 10px;
  margin-top: 20px;
  ${respondTo.sm`
      width: 30%;    
    `}
`;

export default GioccaVinci;
