import React from 'react';
import styled, { css } from 'styled-components';

function Heading(props) {
  return <HeadingContainer {...props} />;
}

const HeadingContainer = styled.div`
  ${props => css`
    font-size: ${props.size || 24}px;
    font-weight: ${props.weight || 'bold'};
    color: ${props.color};
    ::before {
      content: '';
      display: block;
      width: 35px;
      height: 5px;
      background-color: ${props.theme.primary};
      margin: 10px 0;
    }
  `}
`;

export default Heading;
