import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;

    cursor: pointer;
    font-size: 18px;

    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    & input:checked ~ .checkmark {
      background-color: ${props => props.theme.primary};
    }

    & .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      border: 1px solid
        ${props =>
          (!props.isvalid && props.istouched && props.firstSubmit) ||
          (!props.isvalid && props.firstSubmit)
            ? props.theme.input.error
            : props.theme.input.bg};
      border-radius: 50%;
      background-color: ${props => props.theme.input.bg};
    }
  }
`;

const Radio = props => {
  const { radioInputs } = props;

  return (
    <Container onChange={props.onChange} {...props}>
      {radioInputs.map((radio, indx) => {
        return (
          <label htmlFor={radio.elementConfig.id} key={indx}>
            {radio.label}
            <input {...radio.elementConfig} />
            <span className='checkmark'></span>
          </label>
        );
      })}
    </Container>
  );
};

export default Radio;
