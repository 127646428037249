import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Box = props => {
  return <BoxWrap {...props}>{props.children}</BoxWrap>;
};

const BoxWrap = styled.div`
  overflow: hidden;
  background-color: ${props =>
    props.primary ? props.theme.primary : props.bg ? props.bg : '#fff'};
  box-shadow: ${props => props.shadow && '0px 6px 24px #3118181a'};
  border-radius: 16px;
  padding: ${props => props.padding || 16}px;
  width: 100%;
  height: ${props => (props.fullheight ? '100%' : 'auto')};
`;

Box.propsTypes = {
  align: PropTypes.string,
  justify: PropTypes.string,
  wrap: PropTypes.string,
  fullheight: PropTypes.bool,
  primary: PropTypes.bool,
};

export default Box;
