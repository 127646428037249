import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  news: [],
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NEWS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: action.news,
      };
    case actionTypes.GET_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default newsReducer;
