import React from 'react';
import { useTranslation } from 'react-i18next';

import SigninForm from '../../components/SigninForm/SigninForm';
import AuthTemplate from '../../templates/AuthTemplate';
import AuthForm from '../../components/AuthForm/AuthForm';

const Login = props => {
  const { logo, logoinverted } = props;

  const { from } = props.location.state || { from: '/' };
  const [t] = useTranslation();

  return (
    <AuthTemplate logo={logo} logoinverted={logoinverted} titlepage={t('auth.signinlabel')}>
      <AuthForm whereToGo={from} />
    </AuthTemplate>
  );
};

export default Login;
