import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  orders: [],
  confirmOrderMessage: '',
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDERS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.orders,
      };
    case actionTypes.GET_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.GET_CONFIRM_ORDERS_MESSAGE:
      return {
        ...state,
        confirmOrderMessage: action.message,
      };
    default:
      return state;
  }
};

export default ordersReducer;
