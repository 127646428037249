import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';
import { Text } from '../../Ui';
import NavigationItem from './NavigationItem/NavigationItem';

const NavUl = styled.ul`
  list-style: none;

  ${respondTo.md`
    display: flex;
    align-items: center;
    height: 100%;
    box-shadow: none;
    margin-left: auto;
	`}

  .only-mobile {
    display: block;
    border-top: 1px solid #dce4e8;
    padding: 15px 0;

    ${respondTo.md`
      display: none;
    `}
  }
`;

const NavigationItems = props => {
  const { pointsEnabled, isLogged, multipromo, i18l, idGame, isOpen, logout, userType } = props;

  const [t] = useTranslation();

  return (
    <NavUl isOpen={isOpen}>
      {/*   {multipromo ? (
        <NavigationItem link='/' active='true'>
          <Text>{i18l.nav.allthepromo ? i18l.nav.allthepromo : t('app.allthepromo')}</Text>
        </NavigationItem>
      ) : null} */}
      {/*   {isLogged && ( */}
      <>
        <NavigationItem link='/guide' active='true'>
          <Text size={10} weight={500}>
            GUIDA
          </Text>
        </NavigationItem>

        {userType === 'installatore' ? (
          <NavigationItem link='/load-ddt' active='true'>
            <Text size={10} weight={500}>
              DDT/FATTURE
            </Text>
          </NavigationItem>
        ) : (
          <NavigationItem link='/user/profile' active='true'>
            <Text size={10} weight={500} upper>
              Codice plus
            </Text>
          </NavigationItem>
        )}

        {pointsEnabled && (
          <NavigationItem link='/prizes' active='true'>
            <Text size={10} weight={500}>
              PREMI
            </Text>
          </NavigationItem>
        )}

        <NavigationItem link='/contact' active='true'>
          <Text size={10} weight={500}>
            AIUTO
          </Text>
        </NavigationItem>
        {/* {isLogged && (
          <div className='only-mobile' onClick={logout}>
            <Logout label={t('auth.logoutlabel')} />
          </div>
        )} */}
      </>

      {i18l.nav.menu_static_links
        ? i18l.nav.menu_static_links.map((item, i) => {
            return (
              <NavigationItem link={item.url} active='true' key={i}>
                <Text>{item.label}</Text>
              </NavigationItem>
            );
          })
        : null}
    </NavUl>
  );
};

NavigationItems.propTypes = {
  isLogged: PropTypes.string,
};
export default NavigationItems;
