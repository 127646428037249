import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Flex } from '../Ui';

function Pagination({ currentPage, totalePages }) {
  //<Pagination carrentPage={} totalePage={}/>
  const location = useLocation();

  return (
    totalePages !== 1 && (
      <Container>
        <Flex justify='center' align='center'>
          <Link
            to={`${location.pathname}?page=1`}
            className={`go-first pageNum ${+currentPage === 1 ? 'disabled' : ''}`}
          >
            {'<<'}
          </Link>

          <Link
            to={`${location.pathname}?page=${+currentPage > 1 ? +currentPage - 1 : +currentPage}`}
            className={`prev pageNum ${+currentPage === 1 ? 'disabled' : ''}`}
          >
            {'<'}
          </Link>
          <Flex gap={3} style={{ margin: '0 10px' }}>
            {Array(totalePages)
              .fill()
              .map((page, index) => (
                <Link
                  to={`${location.pathname}?page=${index + 1}`}
                  className={`pageNum ${index + 1 === +currentPage ? 'active' : ''}`}
                >
                  {index + 1}
                </Link>
              ))}
          </Flex>
          <Link
            to={`${location.pathname}?page=${
              +currentPage < +totalePages ? +currentPage + 1 : +currentPage
            }`}
            className={`next pageNum ${+totalePages === +currentPage ? 'disabled' : ''}`}
          >
            {'>'}
          </Link>
          <Link
            to={`${location.pathname}?page=${+totalePages}`}
            className={`go-last pageNum ${+currentPage === +totalePages ? 'disabled' : ''}`}
          >
            {'>>'}
          </Link>
        </Flex>
      </Container>
    )
  );
}
const Container = styled.div`
  .active {
    color: red;
  }
  .prev,
  .next,
  .pageNum,
  .go-first,
  .go-last {
    cursor: pointer;
    border: 1px solid ${props => props.theme.primary};
    padding: 5px 10px;
    margin: 5px;
    border-radius: 2px;
  }
  .prev,
  .next,
  .go-first,
  .go-last {
    font-size: 20px;
    font-weight: bold;
    border: none;
    background-color: #000 !important;
    color: #fff;
  }
  .pageNum.active,
  .pageNum:hover {
    background-color: ${props => props.theme.primary};
    color: #fff;
  }

  .prev.disabled,
  .next.disabled,
  .go-first.disabled,
  .go-last.disabled {
    background-color: #555 !important;
    color: #fff;
  }
`;

export default Pagination;
