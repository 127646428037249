import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "../../Ui";
import { SingleExternalOauth } from "../index";
import ModalLoginFlow from "../../ModalLoginFlow";

// COMPONENT TO HANDLE OAUTHS -- FOR EXAMPLE INSIDE MECHANICGOTOGAME -- We have to open a modal with the login flow
// or if we have just one external login and not the ownlogin, show it
const OauthsHandler = (props) => {
	const [showModal, setShowModal] = useState(false);

	const {
		label,
		isfullwidth,
		logo,
		authObj: { oauthInfo, oauths, ownLogin, isLogged },
	} = props;

	useEffect(() => {
		if (isLogged) {
			setShowModal(false);
		}
	}, [isLogged]);

	const closeModalHandler = () => {
		setShowModal(false);
	};

	return (
		<div>
			{oauths.length === 1 && !ownLogin ? (
				// If we have only one oauths and no ownlogin (Stardust Play) // Single external oauth
				<SingleExternalOauth
					oauths={oauths}
					label={label}
					oauthInfo={oauthInfo}
					isfullwidth={isfullwidth}
				/>
			) : (
				<Button
					onClick={() => setShowModal(!showModal)}
					fullwidth={isfullwidth}
				>
					<span>{label}</span>
				</Button>
			)}
			<Modal show={showModal} close={closeModalHandler}>
				{ownLogin ? <ModalLoginFlow logo={logo} /> : null}
			</Modal>
		</div>
	);
};

OauthsHandler.propTypes = {
	label: PropTypes.string,
	oauthInfo: PropTypes.object,
	oauths: PropTypes.array,
};

export default OauthsHandler;
