import * as actionTypes from '../actions/actionTypes';

const initialState = {
  user: {
    wishlist: [],
    cart: [],
  },
  loading: true,
  error: null,
  cartError: null,
  activatedloading: true,
  activated: false,
  activatederror: null,
  userLogged: null,
  userInfoUpdateSuccess: false,
  userInfoUpdateError: false,
  loadingChangePsw: false,
  passwordChanged: false,
  errorChangePsw: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      };
    case actionTypes.GET_USER_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.SET_USER_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfoUpdateSuccess: true,
        error: null,
        user: {
          ...state.user,
          userinfo: action.data,
        },
      };
    case actionTypes.SET_USER_INFO_ERROR:
      return {
        ...state,
        loading: false,
        userInfoUpdateError: action.error,
      };
    case actionTypes.SET_USER_INFO_CLEAR:
      return {
        ...state,
        loading: false,
        userInfoUpdateSuccess: null,
        userInfoUpdateError: null,
      };
    case actionTypes.USER_ACTIVATION_START:
      return {
        ...state,
        activatedloading: true,
      };
    case actionTypes.USER_ACTIVATION_SUCCESS:
      return {
        ...state,
        activated: true,
        activatedloading: false,
      };
    case actionTypes.USER_ACTIVATION_ERROR:
      return {
        ...state,
        activatederror: action.error,
        activatedloading: false,
      };
    case actionTypes.USER_ACTIVATION_CLEAR_ERROR:
      return {
        ...state,
        activatedloading: false,
        activatederror: null,
      };
    case actionTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        loadingChangePsw: true,
      };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingChangePsw: false,
        passwordChanged: true,
      };
    case actionTypes.CHANGE_PASSWORD_CLEAR:
      return {
        ...state,
        loadingChangePsw: false,
        passwordChanged: null,
      };
    case actionTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loadingChangePsw: false,
        errorChangePsw: action.error,
      };
    case actionTypes.CHANGE_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        loadingChangePsw: false,
        errorChangePsw: null,
      };
    case actionTypes.TO_CART_ERROR:
      return {
        ...state,
        cartError: action.error,
      };
    case actionTypes.TO_CART_CLEAR_ERROR:
      return {
        ...state,
        cartError: null,
      };
    case actionTypes.TO_CART_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          cart: action.cart,
        },
        isLoading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
