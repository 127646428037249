import parse from 'html-react-parser';
import React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  .react-switch {
    border-radius: 32px !important;
    border: 2px solid
      ${props =>
        (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
          ? props.theme.input.error
          : props.value === 1
          ? props.theme.primary
          : '#6e6e6e'};
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    color: ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : '#000000'};

    /* &:before {
      content: '';
      flex: 0 0 24px;
      height: 24px;
      margin-right: 10px;
      display: inline-block;
      border-radius: 7px;
      border: 2px solid
        ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.value === 1
        ? props.theme.primary
        : props.theme.input.bg};
      background-color: ${props =>
      props.value === 1 ? props.theme.primary : props.theme.input.bg};
      transition: all 150ms;
    } */

    a {
      color: #000;
      font-weight: 500;
      text-decoration: underline;
      color: ${props =>
        (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
          ? props.theme.input.error
          : '#000000'};
    }
  }
`;

const Checkbox = props => {
  return (
    <CheckboxContainer
      isvalid={props.isvalid}
      istouched={props.istouched}
      firstSubmit={props.firstSubmit}
      value={parseInt(props.value)}
    >
      {/* <HiddenCheckbox {...props} /> */}
      <label htmlFor={props.name}>{parse(props.label)}</label>
      <Switch
        name={props.name}
        checked={Boolean(props.value)}
        onChange={checked =>
          props.onChange({
            target: { name: props.name, value: checked, checked, type: 'checkbox' },
          })
        }
        className='react-switch'
        uncheckedIcon={false}
        checkedIcon={false}
        offColor='#6E6E6E'
        onColor='#FF000F'
        borderRadius={32}
        height={26}
        checkedHandleIcon={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              color: '#FF000F',
              fontSize: 18,
            }}
          >
            <svg
              id='Raggruppa_234'
              data-name='Raggruppa 234'
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
            >
              <path id='Tracciato_70' data-name='Tracciato 70' d='M0,0H24V24H0Z' fill='none' />
              <path
                id='Tracciato_71'
                data-name='Tracciato 71'
                d='M10,15.172l9.192-9.193,1.415,1.414L10,18,3.636,11.636,5.05,10.222Z'
                fill='#ff000f'
              />
            </svg>
          </div>
        }
      />
    </CheckboxContainer>
  );
};

export default Checkbox;
