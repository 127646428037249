import customAxios from '../../config/axios-refresh-token';
import * as actionTypes from './actionTypes';

export const setConfirmOrderMessage = message => {
  return {
    type: actionTypes.GET_CONFIRM_ORDERS_MESSAGE,
    message,
  };
};

const getOrdersStart = () => {
  return {
    type: actionTypes.GET_ORDERS_START,
  };
};

const getOrdersSuccess = orders => {
  return {
    type: actionTypes.GET_ORDERS_SUCCESS,
    orders,
  };
};

const getOrdersError = error => {
  return {
    type: actionTypes.GET_ORDERS_ERROR,
    error,
  };
};

export const getOrders = (page = 1) => {
  let endpoint = `/catalog/orders?page=${page}`;
  return dispatch => {
    dispatch(getOrdersStart());
    // return new Promise((resolve, reject) => {
    return customAxios
      .get(endpoint)
      .then(response => {
        // resolve();
        return dispatch(getOrdersSuccess(response.data));
      })
      .catch(error => {
        dispatch(getOrdersError(error));
        // reject(error);
        // });
      });
  };
};
