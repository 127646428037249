import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Text } from '../Ui';
import { respondTo } from '../../theme/mixin';
const PrizeBox = ({ id, title, brand, image, ...rest }) => {
  return (
    <PrizeBoxWrapper {...rest} className='prize-box'>
      <img src={image} alt='' />
      <Text margin='8px 0' primary size={12} bold>
        {brand}
      </Text>
      <Text margin='0 0 8px' size={12} bold>
        <Link to={'/prizes'}>{title}</Link>
      </Text>
      <Button active fullwidth style={{ marginTop: 'auto' }}>
        <Link to={'/prizes/' + id} style={{ padding: '15px 0' }}>
          SCOPRI DI PIÙ
        </Link>
      </Button>
    </PrizeBoxWrapper>
  );
};

const PrizeBoxWrapper = styled.div`
  overflow: hidden;
  padding: 16px;
  display: flex !important;
  flex-direction: column;

  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;

    ${respondTo.sm` 
      height: 205px;
    `}
  }
  .brand {
    outline: none;
  }
`;

export default PrizeBox;
