import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import { LastLocationProvider } from 'react-router-last-location';

import AuthLayout from '../layouts/AuthLayout';
import BaseLayout from '../layouts/BaseLayout';

import Advantages from '../views/Advantages/Advantages';
import Auth from '../views/Auth';
import FormWin from '../views/FormWin/FormWin';
import GioccaVinci from '../views/GioccaVinci';
import Home from '../views/Home';
import NotFound from '../views/NotFound';
import Prizes from '../views/Prizes';
import SingleAdvantage from '../views/SingleAdvantage/SingleAdvantage';
import Landing from '../views/Landing';
import Citofonia from '../views/Activities/Citofonia';
import Monopattino from '../views/Activities/Monopattino';
import October from '../views/Activities/October';

const Register = React.lazy(() => import('../views/Register'));
const Game = React.lazy(() => import('../views/Game'));
const Initiative = React.lazy(() => import('../views/Initiative'));
const Login = React.lazy(() => import('../views/Login'));
const RecoveryPassword = React.lazy(() => import('../views/RecoveryPassword'));
const ResetPassword = React.lazy(() => import('../views/ResetPassword'));
const Profile = React.lazy(() => import('../views/Profile'));
const UserActivated = React.lazy(() => import('../views/UserActivated'));
const Oauth = React.lazy(() => import('../views/Oauth'));
const Panel = React.lazy(() => import('../views/Panel'));
const Awards = React.lazy(() => import('../views/Awards'));
const Contact = React.lazy(() => import('../views/Contact/Contact'));
const AwardsHistory = React.lazy(() => import('../views/AwardsHistory/AwardsHistory'));
const DDTLoaded = React.lazy(() => import('../views/DDTLoaded/DDTLoaded'));
const DDTSingle = React.lazy(() => import('../views/DDTSingle/DDTSingle'));
const PointDetais = React.lazy(() => import('../views/PointDetails/PointDetails'));
const Guide = React.lazy(() => import('../views/Guide/Guide'));
const Partecipate = React.lazy(() => import('../views/Activities/Partecipate'));
const Aprile = React.lazy(() => import('../views/Activities/Aprile'));
const Settembre = React.lazy(() => import('../views/Activities/Settembre'));
const Novembre = React.lazy(() => import('../views/Activities/Novembre'));
const Dicembre = React.lazy(() => import('../views/Activities/Dicembre'));
const Roma = React.lazy(() => import('../views/Activities/Roma'));
const ShopProduct = React.lazy(() => import('../views/Activities/ShopProduct'));
const Invite = React.lazy(() => import('../views/Activities/Invite'));
const CheckNews = React.lazy(() => import('../views/Activities/CheckNews'));
const SellProduct = React.lazy(() => import('../views/Activities/SellProduct'));
const Zenit = React.lazy(() => import('../views/Activities/Zenit'));

const DDTFactory = React.lazy(() => import('../views/DDTFactory/DDTFactory'));
const Notifications = React.lazy(() => import('../views/Notifications/Notifications'));
const SingleNotification = React.lazy(() =>
  import('../views/SingleNotification/SingleNotification'),
);
const SinglePrize = React.lazy(() => import('../views/SinglePrize'));
const SingleProduct = React.lazy(() => import('../views/SingleProduct/SingleProduct'));
const News = React.lazy(() => import('../views/News/News'));
const SingleNews = React.lazy(() => import('../views/SingleNews/SingleNews'));
const Cart = React.lazy(() => import('../views/Cart/Cart'));
const ConfirmOrder = React.lazy(() => import('../views/ConfirmOrder/ConfirmOrder'));
const OrderSuccess = React.lazy(() => import('../views/OrderSuccess/OrderSuccess'));
const AssociatedInstallers = React.lazy(() =>
  import('../views/AssociatedInstallers/AssociatedInstallers'),
);

const history = createBrowserHistory();
const InstallerRoute = ({ children }) => {
  const userType = useSelector(state => state.user.user?.userinfo?.type);

  return userType === 'installatore' ? children : <Redirect to='/' />;
};
const PrivateRoute = ({ component: Component, layoutCmp: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        rest.isLogged !== null ? (
          <Layout {...props} {...rest} cmp={Component} />
        ) : (
          <Redirect
            to={{
              pathname: !rest.ownLogin ? '/' : '/auth/login',
              state: {
                from: rest.location.pathname,
                isLogged: rest.isLogged,
              },
            }}
          />
        )
      }
    />
  );
};

const Routes = props => {
  const { ownLogin, multipromo, userType } = props;

  const pointsEnabled = useSelector(state => state.user.user?.points_enabled);

  let ownRoutes = null;

  if (ownLogin) {
    ownRoutes = (
      <React.Fragment>
        {/*  <Route path='/' exact render={route => <AuthLayout cmp={Auth} {...route} />} /> */}
        <Route path='/landing' component={Landing} />
        <Route path='/auth/login' exact render={route => <AuthLayout cmp={Login} {...route} />} />
        <Route
          path='/auth/recovery-password'
          exact
          render={route => <AuthLayout cmp={RecoveryPassword} {...route} />}
        />

        <Route
          path='/auth/register'
          exact
          render={route => <AuthLayout cmp={Register} {...route} />}
        />
        <Route
          path='/activate/:id'
          exact
          render={route => <AuthLayout cmp={UserActivated} {...route} />}
        />
        <Route
          path='/reset-password/:onetimetoken'
          exact
          render={route => <AuthLayout cmp={ResetPassword} {...route} />}
        />
      </React.Fragment>
    );
  }

  return (
    <Router history={history}>
      <LastLocationProvider>
        <Switch>
          <Route
            path='/promo/:initiative'
            exact
            render={route => <BaseLayout cmp={Initiative} {...route} />}
          />
          <Route path='/oauth/:connect' exact render={route => <Oauth {...route} />} />
          <Route path='/tags/:tag' exact render={route => <BaseLayout cmp={Home} {...route} />} />
          <Route path='/panel' exact component={Panel} />
          <Route
            path='/'
            exact
            render={route =>
              props.isLogged ? (
                <BaseLayout cmp={Initiative} {...route} />
              ) : (
                <AuthLayout cmp={Auth} {...route} />
              )
            }
          />
          <PrivateRoute
            path='/awards-history'
            exact
            component={AwardsHistory}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/DDT-loaded'
            exact
            component={DDTLoaded}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/point-details'
            exact
            component={PointDetais}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/DDT-loaded/:id'
            exact
            component={DDTSingle}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/guide'
            exact
            component={Guide}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />

          {/* <InstallerRoute> */}
          <PrivateRoute
            path='/activities/shop-product'
            exact
            component={ShopProduct}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/aprile'
            exact
            component={Aprile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/ottobre'
            exact
            component={October}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/roma'
            exact
            component={Roma}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/settembre'
            exact
            component={Settembre}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/novembre'
            exact
            component={Novembre}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
           <PrivateRoute
            path='/activities/dicembre'
            exact
            component={Dicembre}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/citofonia'
            exact
            component={Citofonia}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/partecipate'
            exact
            component={Partecipate}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/monopattino'
            exact
            component={Monopattino}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/invite'
            exact
            component={Invite}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/check-news'
            exact
            component={CheckNews}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/activities/zenit'
            exact
            component={Zenit}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/notifications/:id'
            exact
            component={SingleNotification}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          {/* </InstallerRoute> */}

          <PrivateRoute
            path='/activities/sell-product'
            exact
            component={SellProduct}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/load-ddt'
            exact
            component={DDTFactory}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />

          <PrivateRoute
            path='/promo/:initiative/game/:id'
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type='gamelayout'
          />

          <PrivateRoute
            path='/promo/game/:id'
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type='gamelayout'
          />

          <PrivateRoute
            path='/promo'
            exact
            component={GioccaVinci}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type='gamelayout'
          />
          <PrivateRoute
            path='/advantages'
            exact
            component={Advantages}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <Route
            path='/advantage/:id'
            exact
            render={route => <BaseLayout cmp={SingleAdvantage} {...route} />}
          />
          <PrivateRoute
            path='/user/profile'
            exact
            component={Profile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/contact'
            exact
            component={Contact}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/user/awards'
            exact
            component={Awards}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/form-win'
            exact
            component={FormWin}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />

          <PrivateRoute
            path='/products/:id'
            exact
            component={SingleProduct}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/notifications'
            exact
            component={Notifications}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/news'
            exact
            component={News}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/news/:id'
            exact
            component={SingleNews}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/cart'
            exact
            component={Cart}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/confirme-order'
            exact
            component={ConfirmOrder}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/order-success'
            exact
            component={OrderSuccess}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />

          {userType?.type === 'distributore' && (
            <PrivateRoute
              path='/installatori-associati'
              exact
              component={AssociatedInstallers}
              layoutCmp={BaseLayout}
              isLogged={props.isLogged}
              ownLogin={ownLogin}
            />
          )}

          {pointsEnabled && (
            <>
              <PrivateRoute
                path='/prizes'
                exact
                component={Prizes}
                layoutCmp={BaseLayout}
                isLogged={props.isLogged}
                ownLogin={ownLogin}
              />
              <PrivateRoute
                path='/prizes/:id'
                exact
                component={SinglePrize}
                layoutCmp={BaseLayout}
                isLogged={props.isLogged}
                ownLogin={ownLogin}
              />
            </>
          )}
          {ownRoutes}

          <Route component={NotFound} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    ownLogin: state.app.config.ownlogin?.active,
    multipromo: state.app.config?.settings?.multipromo,
    userType: state.user.user?.userinfo,
  };
};

export default connect(mapStateToProps)(Routes);
