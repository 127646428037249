import React from 'react';
import { ReactComponent as Upload } from '../../assets/images/upload.svg';
import background from '../../assets/images/octoberBanner.png';
import { Box, Button, Text } from '../../components/Ui';
import Layout from './Layout';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function October() {
  const percentage = useSelector(state => state.user.user?.abb_missions?.welcome_bonus_plus);
  const userType = useSelector(state => state.user.user?.userinfo?.type);

  return (
    <Layout banner={background}>
      <Box shadow className='mt-32'>
        <Text bold size={30}>
          {userType === 'installatore'
            ? 'Ad ottobre 2024, con i sezionatori e prodotti di protezione e controllo motori guadagni punti extra!'
            : 'Ad ottobre 2024, con i sezionatori e prodotti di protezione e controllo motori guadagni punti extra!'}
        </Text>
        <Text className='mt-12'>
          {userType === 'installatore'
            ? 'Nel periodo che va dal primo al 31 ottobre 2024, acquista sezionatori e prodotti di protezione e controllo motori e carica i tuoi DDT per maturare 2 punti ogni 5€ di listino.'
            : 'Nel periodo che va dal primo al 31 ottobre 2024, ricorda ai tuoi clienti che acquistando sezionatori e prodotti di protezione e controllo motori e caricando i DDT validi maturano 2 punti ogni 5€ di listino.<br/>Qui trovi il riassunto dei DDT che sono stati caricati con il tuo codice PLUS.'}
        </Text>
        {userType === 'installatore' ? (
          <Link to='/load-ddt'>
            <Button active className='mt-24' upper>
              Carica DDT
              <Upload />
            </Button>
          </Link>
        ) : (
          <Link to='/user/profile'>
            <Button active className='mt-24' upper>
              DDT a te associati
            </Button>
          </Link>
        )}
      </Box>
    </Layout>
  );
}

export default October;
