import customAxios from '../../config/axios-refresh-token';
import * as actionTypes from './actionTypes';

/* const getNewsStart = () => {
  return {
    type: actionTypes.GET_NEWS_START,
  };
};



const getNewsError = error => {
  return {
    type: actionTypes.GET_NEWS_ERROR,
    error,
  };
}; */
const getNewDdtSuccess = data => {
  return {
    type: actionTypes.GET_NEW_DDT_SUCCESS,
    data,
  };
};
export const setDdtUpload = file => {
  return {
    type: actionTypes.GET_FILE_DDT_UPLOAD,
    ddtFileUploaded: file,
  };
};

export const getUpload = boolean => {
  return {
    type: actionTypes.GET_UPLOAD_START,
    upload: boolean,
  };
};

export const newDdtUpload = data => {
  return dispatch => {
    //dispatch(getNewsStart());
    // return new Promise((resolve, reject) => {
    return customAxios
      .post('/ddt/upload', data)
      .then(response => {
        dispatch(getNewDdtSuccess(response.data));
        dispatch(getUpload(true));
      })
      .catch(error => {
        // dispatch(getNewsError(error));
        // reject(error);
        // });
      });
  };
};
