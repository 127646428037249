import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  ddtFileUploaded: '',
  upload: false,
  newddt: {},
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FILE_DDT_UPLOAD:
      return {
        ...state,
        ddtFileUploaded: action.ddtFileUploaded,
      };
    case actionTypes.GET_UPLOAD_START:
      return {
        ...state,
        upload: action.upload,
      };

    case actionTypes.GET_NEW_DDT_SUCCESS:
      return {
        ...state,
        newddt: action.data,
      };
    default:
      return state;
  }
};

export default newsReducer;
