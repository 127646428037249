import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Dialog } from '../../assets/images/icon-dialog.svg';
import logoFooter from '../../assets/images/logo-Footer.png';
import { ReactComponent as ToUp } from '../../assets/images/toUp.svg';
import { Text, Wrapper } from '../Ui';

const FooterContainer = styled.footer`
  margin-top: 80px;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px -5px 15px #00000026;
`;

const TopBar = styled.div`
  background-color: #fff;
  padding: 15px 0 10px 0;
  text-align: center;
  position: relative;

  img {
    max-width: 110px;
  }
`;

const BottomBar = styled.div`
  background-color: #6e6e6e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 10px 18px 10px;
  color: #fff;
  .dialog {
    width: 20px;
    height: 20px;
  }

  img {
    max-width: 20px;
    height: auto;
  }

  .text {
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    color: #fff;
    p {
      padding: 0 3px;
    }

    a {
      font-size: 8px;
      padding: 0 3px;
      color: ${({ theme }) => theme.primary};
      text-decoration: underline;
      font-weight: bold;
    }
  }
`;

const IconUp = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  background: #d2d2d2;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
  color: ${props => props.theme.primary};
  flex: 0 0 30px;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 14px;
    height: 14px;
  }
`;

const Footer = props => {
  const { footercontent } = props;

  // Get info from CMS section
  const footerlogo = footercontent[0]?.contents?.find(el => el.name === 'logo');
  const footertext = footercontent[0]?.contents?.find(el => el.name === 'text');
  const isLogged = useSelector(state => state.auth.token);
  return (
    <Wrapper>
      <FooterContainer>
        <TopBar>
          {/*   <FooterLogo /> */}
          <img src={logoFooter} alt='logoFooter' />
          <IconUp onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            <ToUp />
          </IconUp>
        </TopBar>
        <BottomBar>
          {isLogged ? (
            <Link to='/'>
              <Dialog className='dialog' />
            </Link>
          ) : null}

          <div className='text'>
            <div className='mb-10'>
              <Text align='center' light color='#fff'>
                *Operazione a premi “ABB PREMI PLUS 2024-2025” riservata agli installatori e al 
                personale dipendente dei distributori, valida dal 15/03/2024 al 31/12/2025, con richiesta premi 
                fino al 31/03/2026. Montepremi stimato € 300.000,00 + IVA ove dovuta. Regolamento{' '}
                <a
                  href={`https://abbpremiplus.it/app/MODIFICA_Regolamento_OP_ABB_Premi_Plus_2024_2025.pdf`}
                  target='blank'
                  className='link'
                >
                  QUI.
                </a>
              </Text>
            </div>
            <div>
              <Text align='center' light color='#fff'>
                **Concorso riservato agli installatori valido dal 01.04.2024 al 30.11.2024 nei
                seguenti periodi: 1° periodo: dal 1° aprile 2024 al 30 aprile 2024 / 2° periodo: dal
                1° giugno 2024 al 30 giugno 2024 / 3° periodo: dal 1° settembre 2024 a 30 settembre
                2024 / 4° periodo: dal 1° novembre 2024 al 30 novembre 2024. Stima valore montepremi
                € 20.237,67 + IVA ove dovuta. Regolamento{' '}
                <a
                  href={`https://abbpremiplus.it/app/regolamento-concorso.pdf`}
                  target='blank'
                  className='link'
                >
                  QUI.
                </a>
              </Text>
            </div>
          </div>
        </BottomBar>
      </FooterContainer>
    </Wrapper>
  );
};

Footer.defaultProps = {
  content: '',
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;
