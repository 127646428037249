import React from 'react';
import styled from 'styled-components';
import emailIcon from '../../../../assets/images/email-icon.svg';
import usernameIcon from '../../../../assets/images/username-icon.svg';
import Checkbox from '../Checkbox/Checkbox';
import { useState } from 'react';
import { LiaEyeSlash, LiaEyeSolid } from 'react-icons/lia';

const InputWrap = styled.div`
  position: relative;
  .show_pw {
    position: absolute;
    right: 10px;
    top: 60%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

const InputField = styled.input`
  border: none;
  /* color: ${props => props.theme.primary}; */
  color: #000;
  padding: 0 15px;
  background-color: ${props => props.theme.input.bg};
  /* border-radius: 32px; */
  width: ${props => (props.type === 'checkbox' ? '20px' : '100%')};
  height: 32px;
  font-size: 12px;
  font-weight: 500;
  outline: none;
  opacity: ${props => (props.readonly ? '0.3' : '1')};
  font-family: ${props => props.theme.fontFamily};
  border-radius: 16px;
  border: 2px solid
    ${props => {
      return (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg;
    }};
  &::placeholder {
    color: #a9a9a9;
  }
`;

const InputIcon = styled.div`
  position: absolute;
  top: 13px;
  right: 10px;
`;

const Input = props => {
  const [passwordShown, setPasswordShown] = useState(0);

  const togglePassword = show => {
    if (props.form) {
      const updateForm = {
        ...props.form,
      };
      updateForm[props.name].elementConfig.type = show ? 'text' : 'password';
      props.setForm && props.setForm(updateForm);
    }

    setPasswordShown(show ? 1 : 0);
  };
  return (
    <InputWrap>
      <InputField {...props} {...props.elementConfig} />
      {props.showPwd && (
        <div className='show_pw'>
          {passwordShown ? (
            <LiaEyeSlash onClick={() => togglePassword(false)} />
          ) : (
            <LiaEyeSolid onClick={() => togglePassword(true)} />
          )}
        </div>
      )}
      {props.icon && (
        <>
          <InputIcon>
            <img
              src={
                props.icon === 'username'
                  ? usernameIcon
                  : /*  : props.icon === 'password'
                  ? passwordIcon */
                  props.icon === 'email'
                  ? emailIcon
                  : null
              }
              alt=''
            />
          </InputIcon>
        </>
      )}
    </InputWrap>
  );
};

export default Input;
