import React,{ useState } from 'react'
import InputField from "../InputField/InputField";


const InputPrice = (props) => {

  const [isEditing,setIsEditing] = useState(false)

  const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });

  const toggleEditing = () => {
    setIsEditing( !isEditing );
  }

  return (
    isEditing ? (

      <InputField
        {...props.elementConfig}
        onChange={props.changed}
        {...props}
        onBlur={toggleEditing}
        placeholder="0,00 €"
      />

    ) : (
      <InputField
        {...props.elementConfig}
        {...props}
        type="text"
        value={props.value ? formatter.format(props.value) : ''}
        onFocus={toggleEditing}
        readOnly
      />
    )
  )
}

export default InputPrice
