import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { fontSize } from 'styled-system';
import { respondTo } from '../../../theme/mixin';
import { theme } from '../../../theme/theme';
const TextContainer = styled.span`
  display: block;
  ${({ size, mSize }) =>
    size
      ? css`
          font-size: ${mSize || size}px;
          ${respondTo.sm`
           font-size: ${size}px;
         `}
        `
      : fontSize};
  font-weight: ${props =>
    props.xBold
      ? props.xBold
      : props.bold
      ? 'bold'
      : props.light
      ? '300'
      : props.weight
      ? props.weight
      : '400'};
  text-align: ${props => (props.MAlign ? props.MAlign : props.align)};
  ${respondTo.sm`
  text-align: ${props => props.align};

  `}
  color: ${props =>
    props.color
      ? props.color
      : props.primary
      ? props.theme.primary
      : props.white
      ? props.theme.default_colortext
      : props.theme.secondary};
  ${({ clamp }) =>
    clamp &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
  &:first-letter {
    text-transform: ${props => (props.capit ? 'capitalize' : props.upper)};
  }
  text-transform: ${props => (props.upper ? 'uppercase' : 'none')};

  width: ${props => props.width};
  margin: ${props => props.margin};
  ul {
    padding-left: 20px;
  }
`;

const Text = props => {
  const textStyles = theme.textStyles;
  return (
    <TextContainer {...props} {...textStyles[props.type || 'textDetails']}>
      {typeof props.children === 'number'
        ? props.children
        : /<\/?[a-z][\s\S]*>/i.test(props.children)
        ? parse(props.children) || ''
        : props.children}
    </TextContainer>
  );
};

/* Text.defaultProps = {
  size: 16,
  align: 'left',
}; */

Text.propTypes = {
  children: PropTypes.any,
  size: PropTypes.number,
  align: PropTypes.string,
  white: PropTypes.bool,
  bold: PropTypes.bool,
  light: PropTypes.bool,
  upper: PropTypes.bool,
};

export default Text;
