import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { signinform } from '../../formsConfig/formsConfig';
import useForm from '../../hooks/useForm';
import * as actionCreators from '../../store/actions';
import { getSlug } from '../../utils/utils';
import ErrorInModal from '../ErrorInModal';
import { MoreOauths } from '../Oauths';
import { Button, Flex, Input, Loader, Modal, Text } from '../Ui';

import AuthContentWrapper from '../AuthContentWrapper/AuthContentWrapper';

const SigninForm = props => {
  const {
    inModal,
    error,
    clearError,
    signinInit,
    loading,
    redirect,
    whereToGo,
    oauths,
    oauthInfo,
    isOauth,
    oauthSendToken,
  } = props;

  const [showModal, setShowModal] = useState(false);

  const inputRef = useRef();
  const lastLocation = useLastLocation();
  const signInForm = signinform(inputRef, inModal);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
    return () => {
      clearError();
    };
  }, []);

  useEffect(() => {
    if (error !== null) {
      if (error) {
        setShowModal(true);
      }
    }
    return () => {};
  }, [error]);

  const login = () => {
    signinInit(formData.email.value, formData.password.value);
  };

  const modalHandler = () => {
    setShowModal(false);
    clearError();
  };

  const { inputChangedHandler, formData, formSubmitHandler, firstSubmit, setFormData } = useForm(
    login,
    signInForm,
  );

  const [t] = useTranslation();

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        form={formData}
        setForm={setFormData}
        {...inp}
      />
    );
  });

  let oauthsContent = null;
  if (oauths !== undefined) {
    if (oauths.length > 0) {
      oauthsContent = (
        <MoreOauths
          oauths={oauths}
          oauthInfo={oauthInfo}
          inModal={inModal}
          whereToGo={whereToGo}
          isOauth={isOauth}
          oauthSendToken={oauthSendToken}
          lastLocation={lastLocation}
          redirect={redirect}
        />
      );
    }
  }

  if (redirect) {
    let to = whereToGo;
    if (lastLocation !== null) {
      if (
        lastLocation.pathname.split('/')[1] === 'activate' ||
        lastLocation.pathname.split('/')[1] === 'reset-password' ||
        lastLocation.pathname.split('/')[1] === 'auth'
      ) {
        to = '/';
      } else {
        to = lastLocation.pathname;
      }
    }
    if (inModal) {
      to = `/${getSlug()}`;
    }
    return <Redirect to={to} />;
  } else {
    return (
      <>
        <form onSubmit={formSubmitHandler} style={{ height: '100%' }}>
          <AuthContentWrapper title='Accedi' navActive='login'>
            {inputs}
            <Flex>
              <Text style={{ marginRight: 5 }} size={12}>
                Hai dimenticato la
              </Text>
              <Link to='/auth/recovery-password'>
                <Text primary bold style={{ textDecoration: 'underline' }} size={12}>
                  password?
                </Text>
              </Link>
            </Flex>
            <Button upper blackColor type='submit' fullwidth disabled={loading}>
              {loading ? <Loader inBtn show={true} /> : t('auth.signinlabel')}
            </Button>
          </AuthContentWrapper>
        </form>

        <Modal show={showModal} close={modalHandler} type='error'>
          <ErrorInModal error={error} fromTranslation />
        </Modal>
      </>
    );
  }
};

SigninForm.defaultProps = {
  inModal: false,
};

SigninForm.propTypes = {
  inModal: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.number,
  redirect: PropTypes.bool,
  whereToGo: PropTypes.string,
  clearError: PropTypes.func,
  signinInit: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    redirect: state.auth.redirect,
    error: state.auth.errorsignin,
    loading: state.auth.loadingsignin,
    oauths: state.app.config.oauths,
    oauthInfo: state.app.oauthInfo,
    isOauth: state.auth.isOauth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signinInit: (email, password) => dispatch(actionCreators.signinInit(email, password)),
    clearError: () => dispatch(actionCreators.signinClearError()),
    oauthSendToken: (code, connect, data) =>
      dispatch(actionCreators.oauthSendToken(code, connect, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);
