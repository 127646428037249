import customAxios from '../../config/axios-refresh-token';
import * as actionTypes from './actionTypes';

const getNotificationsStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_START,
  };
};

const getNotificationsSuccess = notifications => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    notifications,
  };
};

const getNotificationsError = error => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_ERROR,
    error,
  };
};

export const getNotifications = (page = 1) => {
  let endpoint = `/notices${page ? `?page=${page}` : ''}`;
  return dispatch => {
    dispatch(getNotificationsStart());
    // return new Promise((resolve, reject) => {
    return customAxios
      .get(endpoint)
      .then(response => {
        // resolve();
        return dispatch(getNotificationsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getNotificationsError(error));
        // reject(error);
        // });
      });
  };
};
