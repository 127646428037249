import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import img1Text from '../../assets/images/ABB_KV.png';
import Footer from '../../components/Footer/Footer';
import { Wrapper } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';

const AuthTemplate = props => {
  const { logo, logoinverted, children } = props;

  const footercontent = useSelector(state => state.cms?.footer);
  const authcontent = useSelector(state => state.cms.authentication);

  //const image_auth = authcontent[0].contents.find(e => e.name === 'authentication_image');

  return (
    <Wrapper>
      <SidesWrapper>
        <AuthImage>
          {/* <img src={image_auth?.img || ''} alt='' /> */}
          <img src={img1Text} alt='' className='img-cover' />
          <FormContainer>{children}</FormContainer>
        </AuthImage>
      </SidesWrapper>
      <Footer footercontent={footercontent} />
    </Wrapper>
  );
};

const LogoContainer = styled.div`
  margin-bottom: 10px;
  padding-top: 10px;
`;

const SidesWrapper = styled.div`
  display: block;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 50px;
  margin-left: 0;
  margin-right: 0;

  ${respondTo.sm`
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
  `}
`;

const AuthImage = styled.div`
  width: 100%;
  /* height: 280px; */
  position: relative;
  ${respondTo.sm`
    height: 600px;
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
  }
`;

const FormContainer = styled.div`
  display: flex;
  background-color: #fafafa;
  flex: 0 0 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;

  top: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 0 0 16px 16px;
  ${respondTo.sm`
    flex: 0 0 400px;
    position: absolute;
  border-radius: 16px;
    width: 350px;
  `};
`;

AuthTemplate.propTypes = {
  logo: PropTypes.string,
  titlepage: PropTypes.string,
  children: PropTypes.any,
};

export default AuthTemplate;
