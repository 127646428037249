import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';

import AuthNav from '../../components/AuthNav/AuthNav';
import { Text } from '../../components/Ui';

const AuthContentWrapper = ({ navActive, title, children }) => {
  return (
    <AuthContent>
      <AuthNav active={navActive} />
      {title && (
        <Text as='h5' size={35} bold style={{ marginTop: 20, marginBottom: 10 }}>
          {title}
        </Text>
      )}
      {children}
    </AuthContent>
  );
};

const AuthContent = styled.div`
  /* margin-bottom: 30px; */
  height: 100%;
  display: flex;
  flex-direction: column;

  .subcontainer {
    padding-right: 10px !important;
  }
  button {
    margin-top: 20px;
    ${respondTo.sm`
    margin-top: auto;
  `}
  }

  ${respondTo.sm`
    flex: 0 0 400px;

    .signInContainer {
      margin-bottom: 0;
    }
  `}
`;

AuthContentWrapper.propTypes = {
  title: PropTypes.string,
  navActive: PropTypes.string,
};

export default AuthContentWrapper;
