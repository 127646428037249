import customAxios from '../../config/axios-refresh-token';
import * as actionTypes from './actionTypes';

const getNewsStart = () => {
  return {
    type: actionTypes.GET_NEWS_START,
  };
};

const getNewsSuccess = news => {
  return {
    type: actionTypes.GET_NEWS_SUCCESS,
    news,
  };
};

const getNewsError = error => {
  return {
    type: actionTypes.GET_NEWS_ERROR,
    error,
  };
};

export const getNews = (page = 1) => {
  let endpoint = `/magazine${page ? `?page=${page}` : ''}`;
  return dispatch => {
    dispatch(getNewsStart());
    // return new Promise((resolve, reject) => {
    return customAxios
      .get(endpoint)
      .then(response => {
        // resolve();
        return dispatch(getNewsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getNewsError(error));
        // reject(error);
        // });
      });
  };
};
