import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { ReactComponent as ArrowDown } from '../../assets/images/arrowDown.svg';
import { ReactComponent as Cart } from '../../assets/images/bagcart.svg';
import logoTest from '../../assets/images/logo.png';
import { ReactComponent as Notification } from '../../assets/images/notification.svg';
import { ReactComponent as Points } from '../../assets/images/points.svg';
import { ReactComponent as UserIcon } from '../../assets/images/user.svg';
import { respondTo } from '../../theme/mixin';
import MenuLateral from '../MenuLateral/MenuLateral';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import { Button, Logo, Modal, Text, Wrapper } from '../Ui';

const HeaderSection = styled.header`
  background-color: #fff;
  box-shadow: 0px 5px 15px #00000026;
  border-radius: 14px;
  position: fixed;
  box-shadow: 0px 2px 16px #31181829;
  top: 10px;
  left: 10px;
  right: 10px;
  z-index: 9;
  max-width: 1108px;
  margin: 0 auto;

  ${respondTo.md`
    // margin-left: 0;
    left: 30px;
  right: 30px;
    // position: static;
    // margin-bottom: 30px;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  /*  min-height: ${props => props.theme.header_mobile_height + 'px'}; */

  ${respondTo.sm`
    min-height: ${props => props.theme.header_desktop_height + 'px'};
    gap: 10px;
  `}
  ${respondTo.md`
    min-height: ${props => props.theme.header_desktop_height + 'px'};
    gap: 100px;
  `}
`;

const LogoNavContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px 0;
  flex: 1;
  .logo {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 0 10px;
  }
  .user {
    /* position: relative; */
    margin-right: 20px;
    display: none;
    ${respondTo.md`
      display: block
	`}
    &-info {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      z-index: 1;
      position: relative;
    }

    &-actions {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      padding: 100px 10px 10px;
      border-radius: 14px;
      box-shadow: 0px 2px 16px #31181829;
      /* z-index: -1; */
      width: 200px;
      display: none;
    }
  }
  .user-info:hover + .user-actions,
  .user-actions:hover {
    display: block !important;
  }
  .points {
    display: flex;
    display: none;
    ${respondTo.md`
      display: flex
	`}
    gap: 5px;
    align-items: center;
    .text-point {
      color: #000;
    }
  }

  .notif-cart {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-right: 20px;
    ${respondTo.md`
    margin-right: 0px;

	`}
  }

  .notif,
  .cart {
    position: relative;
  }

  .count {
    position: absolute;
    top: -3px;
    right: -3px;

    color: #fff;

    border-radius: 50%;
    font-size: 10px;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--notif {
      background-color: ${props => props.theme.primary};
    }
    &--cart {
      background-color: #000;
    }
  }

  .classifica {
    border-bottom: 2px solid #eaf0f3;
    ${respondTo.sm`
      margin-right:20px;
      text-align:center;
      border-bottom:none;
	`}
    ${respondTo.md`
      margin-right:20px;
   
	`}
    .point {
      ${respondTo.sm`
     
     
	`}
      ${respondTo.md`
     
	`}
    }
  }
  .text-classifica {
    margin-right: 25px;
  }
  .text {
    width: 80px;
    flex: 1;
    padding: 15px 0;

    ${respondTo.sm`
      flex:initial
      padding:initial;
	`}
    .point {
      ${respondTo.sm`
      font-size:12px;
	`}
      ${respondTo.md`
      font-size:14px;
	`}
    }
    .text-point {
      ${respondTo.sm`
      font-size:9px;
	`}
      ${respondTo.md`
      font-size:10px;
	`}
    }
  }
`;

const NavContainer = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 83px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #dce4e8;
  box-shadow: 0px 5px 15px #00000026;
  border-radius: 0 0 10px 10px;

  ${respondTo.md`
  	display: flex;
  // flex:1;
  position: static;
    justify-content: space-between;
    // margin-left: 20px;
    align-items: center;
    width: initial;
    top: 0;
   
    border-top: none;
    box-shadow: none;

	`}
`;

const Header = props => {
  const { logout, isLogged, multipromo, i18l } = props;
  const user = useSelector(state => state.user.user?.userinfo);
  const cartLength = useSelector(state => state?.user?.user?.cart?.length);
  const games = useSelector(state => state.game.games);
  const unreadNotifications = useSelector(
    state => state.notifications?.notifications?.data?.filter(n => n.read === 0)?.length,
  );
  const pointsEnabled = useSelector(state => state.user.user?.points_enabled);

  const [logoutModal, setLogoutModal] = useState(false);
  const [idGame, setIdGame] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  const logoutFromApp = async () => {
    await logout();
    history.go(0);
  };

  useEffect(() => {
    if (games.length > 0) {
      setIdGame(games[0].id);
    }
  }, [games]);

  return (
    <HeaderSection>
      <Wrapper>
        <HeaderContainer>
          <LogoNavContainer>
            <div className='user'>
              <div className='user-info'>
                <UserIcon />
                <Text size={20} className='name'>
                  {user?.firstname && user.firstname}
                  <br />
                  {user?.lastname && user.lastname}
                </Text>
                <ArrowDown />
              </div>
              <div className='user-actions'>
                <Button style={{ height: 38, fontSize: '10px' }} active fullwidth>
                  <Link to={'/user/profile'}>PAGINA PERSONALE</Link>
                </Button>
                <Button
                  onClick={() => setLogoutModal(true)}
                  style={{ height: 38, fontSize: '10px', marginTop: 10 }}
                  secondary
                  fullwidth
                >
                  LOGOUT
                </Button>
              </div>
            </div>
            <div className='points'>
              <Points />
              <Text primary bold size={22}>
                {user?.score}
              </Text>
              <Text bold size={14} className='text-point'>
                Punti
              </Text>
            </div>
            <Logo className='logo' logo={logoTest} />

            <NavContainer isOpen={isMenuOpen}>
              <NavigationItems
                pointsEnabled={pointsEnabled}
                userType={user?.type}
                isLogged={isLogged}
                multipromo={multipromo}
                i18l={i18l}
                idGame={idGame}
                logout={logout}
              />
            </NavContainer>
            <div className='notif-cart'>
              <Link to='/notifications' className='notif'>
                <Notification />
                {Boolean(unreadNotifications) && (
                  <span className='count count--notif'>{unreadNotifications}</span>
                )}
              </Link>
              {pointsEnabled && (
                <Link to='/cart' className='cart'>
                  <Cart />
                  <span className='count count--cart'>{cartLength ?? 0}</span>
                </Link>
              )}
            </div>
          </LogoNavContainer>
          <MenuLateral
            {...props}
            toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
            isMenuOpen={isMenuOpen}
          />
        </HeaderContainer>
      </Wrapper>
      <Modal show={logoutModal} close={() => setLogoutModal(false)}>
        <Text size={30} bold margin='0 0 32px'>
          Sei sicuro di volerti disconnettere?
        </Text>
        <Button
          style={{ marginBottom: '16px' }}
          active
          fullwidth
          onClick={() => setLogoutModal(false)}
        >
          ANNULLA
        </Button>
        <Button bg_color='#6E6E6E' fullwidth onClick={() => logoutFromApp()}>
          CONFERMA
        </Button>
      </Modal>
    </HeaderSection>
  );
};

Header.propTypes = {
  logo: PropTypes.string,
  appInit: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
  appConfig: PropTypes.object,
};

export default Header;
