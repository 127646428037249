import React from 'react';
import Slider from 'react-slick';

import styled from 'styled-components';
import { ReactComponent as SlideNav } from '../../../assets/images/slideNav.svg';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,

        position: 'absolute',
        bottom: '0',
        right: '10px',
        transform: 'rotate(180deg)',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <SlideNav />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, position: 'absolute', bottom: '0', left: '10px', cursor: 'pointer' }}
      onClick={onClick}
    >
      <SlideNav />
    </div>
  );
}

function Carousel({ children, otherSettings = {}, ...rest }) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: dots => (
      <div style={rest.dotsStyle}>
        <ul
          style={{
            margin: '0px',
            listStyle: 'none',
            display: 'flex',
            gap: 5,
            marginBottom: '10px',
          }}
        >
          {' '}
          {dots}{' '}
        </ul>
      </div>
    ),
    customPaging: i => (
      <div
        className='dot'
        style={{
          width: '10px',
          height: '10px',
          border: '1px solid #FF000F',
          borderRadius: '50%',
          margin: '0px 5px',
          cursor: 'pointer',
        }}
      ></div>
    ),
    ...otherSettings,
  };
  return (
    <Container {...rest}>
      <Slider {...settings}>{children}</Slider>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  .slick-slider {
    height: 100%;
    display: flex;
    flex-direction: column;
    .slick-dots {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: end;
      margin-bottom: 0%;
      padding-top: 35px;
    }
    .slick-dots .slick-active {
      background-color: #ff000f;
      width: 20px;
      border-radius: 10px;
    }
    .slick-list {
      margin: 0 -16px;
    }
    .slick-slide > div {
      padding: 0 16px;
    }
  }
`;

export default Carousel;
